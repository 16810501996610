import PropertiesRepository from '@/api/repositories/properties'
import getSavedState from '@/utils/getSavedState'
import saveStateToStorage from '@/utils/saveStateToStorage'
import cloudinaryImgURL from '@/utils/cloudinaryImgURL'
import { Properties, PropertyHomepageConfig } from './store'
import { MutationTree } from 'vuex'
import { PropertiesMutationTypes } from '@/types/store/mutationTypes/properties'
import { config } from '@/constants'
import getBase64ImageFromURL from '@/utils/getBase64ImageFromURL'

export const state: Properties = {
  property: getSavedState<any>('property') ?? null,
  propertyCode: getSavedState<string>('propertyCode') ?? null,
  propertiesHomepageConfig:
    getSavedState<PropertyHomepageConfig>('propertiesHomepageConfig') ?? null,
  propertyHomepageImageUrl:
    getSavedState<string>('propertyHomepageImageUrl') ?? null,
}

export const getters = {
  property(state) {
    return state.property
  },

  code(state) {
    return state.propertyCode
  },

  atProperty(state): boolean {
    return !!state.propertyCode
  },

  // TODO: Add validation there for when captured data, like ID and photo for certain nationalities at certain types of day depends on it
  propertyIsMannedRightNow(getters): boolean {
    if (
      !getters.property?.unmannedStartTime ||
      !getters.property?.unmannedEndTime
    ) {
      return true
    }

    const unMannedPeriodStarts = new Date(
      // the set date here is unimportant, we just needed this to be a date
      `2022-1-1 ${getters.property.unmannedStartTime}`
    ).toLocaleString('en', {
      timeZone: 'JST',
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit',
    })

    const unMannedPeriodEnds = new Date(
      // the set date here is unimportant, we just needed this to be a date
      `2022-1-1 ${getters.property.unmannedEndTime}`
    ).toLocaleString('en', {
      timeZone: 'JST',
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit',
    })

    const now = new Date()
    const timeNowJst = now.toLocaleString('en', {
      timeZone: 'JST',
      hourCycle: 'h23',
      hour: '2-digit',
      minute: '2-digit',
    })

    const unmannedPeriodStraddlesTheNight =
      unMannedPeriodEnds < unMannedPeriodStarts

    const isMannedRightNow = !(
      timeNowJst > unMannedPeriodStarts && timeNowJst < unMannedPeriodEnds
    )
    const straddlesNightAndIsMannedRightNow =
      timeNowJst < unMannedPeriodStarts && timeNowJst > unMannedPeriodEnds

    return unmannedPeriodStraddlesTheNight
      ? straddlesNightAndIsMannedRightNow
      : isMannedRightNow
  },

  propertiesHomepageConfig(state) {
    return state.propertiesHomepageConfig
  },

  propertyHomepageConfig(state, getters) {
    try {
      if (getters.atProperty) {
        return (
          state.propertiesHomepageConfig[getters.property.acronym] ||
          state.propertiesHomepageConfig.default
        )
      } else {
        return state.propertiesHomepageConfig.default
      }
    } catch {
      return {
        "colors": {
          "foreground": "bg-latte",
          "background": "bg-peach"
        }
      }
    }
  },

  propertyHomepageImageUrl(state) {
    return state.propertyHomepageImageUrl
  },
}

export type Mutations<S = Properties> = {
  [PropertiesMutationTypes.SET_PROPERTY_TOKEN](
    state: S,
    payload: { propertyCode: string | null; save: boolean; expiresAt: number | null }
  ): void
  [PropertiesMutationTypes.SET_PROPERTY](
    state: S,
    payload: { property: any; save: boolean }
  ): void
  [PropertiesMutationTypes.RESET_PROPERTY](state: S): void
}

export const mutations: MutationTree<Properties> & Mutations = {
  SET_PROPERTY_TOKEN(
    state,
    { propertyCode, save = false, expiresAt } = { propertyCode: null, save: false, expiresAt: null }
  ) {
    state.propertyCode = propertyCode
    if (save) saveStateToStorage<Properties>('propertyCode', propertyCode, expiresAt)
  },

  SET_PROPERTY(
    state,
    { property, save = false } = { property: null, save: false }
  ) {
    state.property = property
    if (save) saveStateToStorage<Properties>('property', property)
  },

  SET_PROPERTIES_HOMEPAGE_CONFIG(
    state,
    { propertiesConfig, save = false } = { propertiesConfig: null, save: false }
  ) {
    state.propertiesHomepageConfig = propertiesConfig
    if (save)
      saveStateToStorage<Properties>(
        'propertiesHomepageConfig',
        propertiesConfig
      )
  },

  SET_PROPERTY_HOMEPAGE_IMAGE_URL(
    state,
    { propertyImageUrl, save = false } = { propertyImages: null, save: false }
  ) {
    state.propertyHomepageImageUrl = propertyImageUrl
    if (save)
      saveStateToStorage<Properties>(
        'propertyHomepageImageUrl',
        propertyImageUrl
      )
  },

  RESET_PROPERTY(state) {
    state.property = null
    state.propertyCode = null
    saveStateToStorage<Properties>('property', null)
    saveStateToStorage<Properties>('propertyCode', null)
  },
}

export const actions = {
  async fetchAdminProperties() {
    const response = await PropertiesRepository.getAdminProperties()
    return response.data
  },
  async setAdminProperty({ commit, dispatch }, { id }) {
    const properties = await dispatch('fetchAdminProperties')
    await dispatch('fetchPropertyHomepageImage')
    const property = properties.find(p => p.id === id)
    commit('SET_PROPERTY', { property, save: true })
    commit('SET_PROPERTY_TOKEN', { propertyCode: property?.code, save: true })
    return property
  },
  async fetchProperty({ commit }, { passCode } = { passCode: undefined }) {
    const response = await PropertiesRepository.getProperty({ passCode })
    if (response?.status === 200) {
      const property = response.data
      commit('SET_PROPERTY', { property })
      commit('SET_PROPERTY_TOKEN', { propertyCode: passCode, save: true, expiresAt: new Date().getTime() + 1000 * 60 * 60 * 24 })
      return property
    } else {
      return response
    }
  },
  async updateAdminPropertyApprovalRequirement({ commit }, { property }) {
    const response = await PropertiesRepository.updateProperty(property)
    if (response.status === 200) {
      const property = response.data
      commit('SET_PROPERTY', { property })
    }
    return response
  },
  async fetchPropertiesHomepageConfig({ commit }) {
    const nowTimestampInSeconds = Math.floor(Date.now() / 1000)
    const configUrl = `https://res.cloudinary.com/${config.cloudinary.cloudName}/raw/upload/v${nowTimestampInSeconds}/propertyConfig/homepageConfig.json` // TODO: Set this back to the normal config
    await fetch(configUrl).then(response => {
      response.json().then(propertiesConfig => {
        commit('SET_PROPERTIES_HOMEPAGE_CONFIG', {
          propertiesConfig: {
            ...propertiesConfig,
            metadata: { lastFetchedAt: nowTimestampInSeconds },
          },
          save: true,
        })
      })
    })
  },
  async fetchPropertyHomepageImage({ commit, dispatch, getters }) {
    const noConfigPresent = !getters.propertiesHomepageConfig
    const configOutOfDate =
      noConfigPresent ||
      getters.propertiesHomepageConfig?.metadata?.lastFetchedAt <
      Math.floor(Date.now() / 1000 - 60 * 60) // If there is no config, or if it is over 1 hour old, go get it again.

    if (configOutOfDate) await dispatch('fetchPropertiesHomepageConfig')

    const fileName = getters.property?.acronym
      ? getters.property.acronym.toLowerCase()
      : 'default'
    const propertyImageUrl = await getBase64ImageFromURL(
      cloudinaryImgURL(`propertyConfig/homepageImages/${fileName}`, null, 'jpg')
    )

    commit('SET_PROPERTY_HOMEPAGE_IMAGE_URL', { propertyImageUrl, save: true })
  },
  resetProperty({ commit }) {
    commit('RESET_PROPERTY')
  },
}
