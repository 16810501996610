export default function saveStateToStorage<T>(key: string, value: T[keyof T], expiresAt: number | null) {
  if (value) window.localStorage.setItem(key, JSON.stringify(value))
  else window.localStorage.removeItem(key)

  // Set expiry time
  if (expiresAt) {
    window.localStorage.setItem(`${key}ExpiresAt`, expiresAt.toString())
  } else {
    window.localStorage.removeItem(`${key}ExpiresAt`)
  }
}
